import React from 'react';
import { Link } from 'gatsby';

import { SEO } from '../components/SEO';

const NotFoundPage = () => {
    return (
        <div
            style={{
                backgroundColor: '#aaa',
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100vw',
                height: '100vh',
            }}
        >
            <SEO title="404: Not found" keywords={['']} />
            <p style={{ padding: '1em', paddingTop: '3em' }}>
                Oooops, this page has been lost!{' '}
                <Link to={'/'}>Click here</Link> to go back to the homepage.
            </p>
        </div>
    );
};

export default NotFoundPage;
